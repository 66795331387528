import React, { useState } from 'react';
import LogoImage from '../resources/images/logo.png';
import Auth from './Auth';
import styles from '../styles/landingPage.module.css';

const LandingPage: React.FC = () => {
    const [showAuthModal, setShowAuthModal] = useState(false);
    const [authMode, setAuthMode] = useState<'login' | 'signup' | 'join'>('login');

    return (
        <div className={styles.container}>
            <div className={styles.card}>
                <div className={styles.signInSection}>
                    <div className={`${styles.actionCard} ${styles.blackCard}`}>
                        <h2 className={`${styles.cardTitle} ${styles.whiteText}`}>Ready to Play?</h2>
                        <button
                            className={`${styles.primaryButton} ${styles.whiteButton}`}
                            onClick={() => {
                                setAuthMode('login');
                                setShowAuthModal(true);
                            }}
                        >
                            Sign In
                        </button>
                    </div>
                </div>

                <div className={styles.logoSection}>
                    <div className={styles.logoWrapper}>
                        <img
                            src={LogoImage}
                            alt="Game Logo"
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'contain'
                            }}
                        />
                    </div>
                </div>

                <div className={styles.guestSection}>
                    <div className={`${styles.actionCard} ${styles.whiteCard}`}>
                        <h2 className={`${styles.cardTitle} ${styles.blackText}`}>Just Browsing?</h2>
                        <button
                            className={`${styles.secondaryButton} ${styles.blackButton}`}
                            onClick={() => {
                                setAuthMode('join');
                                setShowAuthModal(true);
                            }}
                        >
                            Play as Guest
                        </button>
                    </div>
                </div>
            </div>

            {showAuthModal && (
                <Auth
                    initialMode={authMode}
                    onClose={() => setShowAuthModal(false)}
                />
            )}
        </div>
    );
};

export default LandingPage;
