// Lobby.tsx

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { api } from '../services/api';
import styles from '../styles/Lobby.module.css';
import OtherGames from './lobbyComponents/OtherGames';
import { GameInfo, GameNotification } from '../types/game';
import { useAuth } from '../contexts/AuthContext';
import { Link } from 'react-router-dom';

interface LobbyProps {
    onlineUsers: string[];
    socket: any;
}

const Lobby: React.FC<LobbyProps> = ({ onlineUsers, socket }) => {
    const [username, setUsername] = useState('');
    const [notifications, setNotifications] = useState<GameNotification[]>([]);
    const [allGames, setAllGames] = useState<GameInfo[]>([]);
    const navigate = useNavigate();
    const { username: authUsername, isAuthenticated, userId } = useAuth();

    useEffect(() => {
        if (authUsername) {
            setUsername(authUsername);
        }
    }, [authUsername]);

    useEffect(() => {

        const fetchGames = async () => {
            try {
                const games = await api.getCurrentGames();
                console.log("games in Lobby:", games);
                setAllGames(games);
            } catch (error) {
                console.error('Error fetching games:', error);
                setNotifications(prev => [...prev, {
                    id: Date.now(),
                    title: 'Error',
                    message: 'Failed to fetch games. Please try again.'
                }]);
            }
        };

        fetchGames();
        const interval = setInterval(fetchGames, 3000); // Refresh every 3 seconds

        return () => clearInterval(interval);
    }, []);


    useEffect(() => {
        console.log("socket in Lobby:", socket);
    }, [socket]);

    useEffect(() => {

        console.log("wasuserId in Lobby:", userId);
        console.log("wasusername in Lobby:", username);
        console.log("wasdauthUsername in Lobby:", authUsername);
    }, [userId, username, authUsername]);


    // Unnecesarry right now
    console.log("notifications in lobby:", notifications);



    const handleJoinGame = async (gameId: string) => {
        if (!username) {
            setNotifications(prev => [...prev, {
                id: Date.now(),
                title: 'Error',
                message: 'Please set a username before joining a game.'
            }]);
            return;
        }

        try {
            const existingPlayerId = userId || localStorage.getItem(`playerId_${gameId}`);
            let playerId;

            if (existingPlayerId) {
                console.log(`Player already in game. Using existing playerId: ${existingPlayerId}`);
                playerId = existingPlayerId;
            } else {
                console.log(`Joining game ${gameId} with username ${username}`);
                const response = await api.joinGame(gameId, username);
                playerId = response.playerId;
                localStorage.setItem(`playerId_${gameId}`, playerId);
            }

            localStorage.setItem(`playerName_${gameId}`, username);
            navigate(`/${gameId}`, { state: { username, playerId } });
        } catch (error) {
            console.error('Error joining game:', error);
            setNotifications(prev => [...prev, {
                id: Date.now(),
                title: 'Error',
                message: 'Failed to join game. Please try again.'
            }]);
        }
    };

    const handleDeleteGame = async (gameId: string) => {
        console.log("deleting game:", gameId);
        console.log("username in handleDeleteGame:", username);
        if (!username) {
            setNotifications(prev => [...prev, {
                id: Date.now(),
                title: 'Error',
                message: 'Please set a username before deleting a game.'
            }]);
            return;
        }

        try {
            const creatorId = localStorage.getItem(`creatorId_${gameId}`);
            if (!creatorId) {
                setNotifications(prev => [...prev, {
                    id: Date.now(),
                    title: 'Error',
                    message: 'You are not the creator of this game.'
                }]);
                return;
            }

            await api.deleteGame(gameId, creatorId);
            setAllGames(prevGames => prevGames.filter(game => game.gameId !== gameId));
            setNotifications(prev => [...prev, {
                id: Date.now(),
                title: 'Success',
                message: 'Game successfully deleted.'
            }]);
        } catch (error) {
            console.error('Error deleting game:', error);
            setNotifications(prev => [...prev, {
                id: Date.now(),
                title: 'Error',
                message: 'Failed to delete game. Please try again.'
            }]);
        }
    };

    const sortedGames = [...allGames].sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return dateB.getTime() - dateA.getTime();
    });

    const myGames = sortedGames.filter(game => game.players.includes(username));
    const otherGames = sortedGames.filter(game => !game.players.includes(username));



    return (
        <div className={styles.container}>
            <main className={styles.main}>
                <div className={styles.grid}>
                    <section>
                        <div className={styles.card}>
                            <div className={styles.cardHeader}>
                                <h2 className={styles.cardTitle}>My Games</h2>
                            </div>
                            <div className={styles.cardContent}>
                                <OtherGames
                                    games={myGames}
                                    onJoinGame={handleJoinGame}
                                    onDeleteGame={handleDeleteGame}
                                    username={username}
                                    myGames={true}
                                />
                            </div>
                        </div>
                    </section>
                    <section>
                        <div className={styles.card}>
                            <div className={styles.cardHeader}>
                                <h2 className={styles.cardTitle}>Other Games</h2>
                            </div>
                            <div className={styles.cardContent}>
                                <OtherGames
                                    games={otherGames}
                                    onJoinGame={handleJoinGame}
                                    onDeleteGame={handleDeleteGame}
                                    username={username}
                                    myGames={false}
                                />
                            </div>
                        </div>
                    </section>
                </div>
            </main>

            <footer className={styles.footer}>
                {isAuthenticated && (

                    <Link to="/deck-management" className={styles.manageDecksButton}>
                        Manage Decks
                    </Link>
                )}
            </footer>
        </div>
    );
};

export default Lobby;
