// Auth.tsx

import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import styles from '../styles/auth.module.css';

interface AuthProps {
    initialMode: 'login' | 'signup' | 'join';
    onClose: () => void;
}

const Auth: React.FC<AuthProps> = ({ initialMode, onClose }) => {
    const [mode, setMode] = useState(initialMode);
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState<string>('');
    const { login, signup, joinWithoutSignup } = useAuth();
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => setIsVisible(true), 50);
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        setError('');
    }, [mode]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setError('');

        try {
            if (mode === 'signup') {
                if (password.length < 6) {
                    setError('Password must be at least 6 characters long');
                    return;
                }
                await signup(username, email, password);
            } else if (mode === 'login') {
                await login(username, password);
            } else {
                if (username.length < 3) {
                    setError('Username must be at least 3 characters long');
                    return;
                }
                await joinWithoutSignup(username);
            }
            onClose();
        } catch (error: any) {
            console.error('Auth error:', error);
            if (error.code === 'auth/user-not-found') {
                setError('No account found with this username');
            } else if (error.code === 'auth/wrong-password') {
                setError('Incorrect password');
            } else if (error.code === 'auth/email-already-in-use') {
                setError('Email is already registered');
            } else if (error.code === 'auth/username-already-in-use') {
                setError('Username is already taken');
            } else {
                setError(error.response?.data?.message || 'An error occurred. Please try again.');
            }
        }
    };

    return (
        <div className={`${styles.authModal} ${isVisible ? styles.visible : ''}`}>
            <div className={`${styles.authModalContent} ${isVisible ? styles.visible : ''}`}>
                <button className={styles.closeButton} onClick={onClose}>&times;</button>
                <h2>{mode === 'login' ? 'Login' : mode === 'signup' ? 'Sign Up' : 'Join Game'}</h2>

                {error && <div className={styles.errorMessage}>{error}</div>}

                <form onSubmit={handleSubmit}>
                    <div className={styles.inputGroup}>
                        <input
                            type="text"
                            placeholder="Username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                            className={styles.authInput}
                        />
                    </div>

                    {mode === 'signup' && (
                        <div className={styles.inputGroup}>
                            <input
                                type="email"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                className={styles.authInput}
                            />
                        </div>
                    )}

                    {mode !== 'join' && (
                        <div className={styles.inputGroup}>
                            <input
                                type="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                                className={styles.authInput}
                            />
                        </div>
                    )}

                    <button type="submit" className={styles.submitButton}>
                        {mode === 'login' ? 'Login' : mode === 'signup' ? 'Sign Up' : 'Join Game'}
                    </button>
                </form>
                {mode === 'login' && (
                    <p>
                        Don't have an account?{' '}
                        <button onClick={() => setMode('signup')}>Sign Up</button>
                    </p>
                )}
                {mode === 'signup' && (
                    <p>
                        Already have an account?{' '}
                        <button onClick={() => setMode('login')}>Login</button>
                    </p>
                )}
            </div>
        </div>
    );
};

export default Auth;
