import React, { useState, useEffect } from 'react';
import { api } from '../services/api';
import styles from '../styles/AdminUI.module.css';

type FieldType = {
    name: string;
    type: 'string' | 'boolean' | 'number' | 'date' | 'image' | 'version';
};

const Admin: React.FC = () => {
    const [collections, setCollections] = useState<string[]>([]);
    const [selectedCollection, setSelectedCollection] = useState<string>('');
    const [documents, setDocuments] = useState<any[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [editingDoc, setEditingDoc] = useState<any>(null);

    useEffect(() => {
        fetchCollections();
    }, []);

    useEffect(() => {
        if (selectedCollection) {
            fetchDocuments();
        }
        //eslint-disable-next-line
    }, [selectedCollection, currentPage]);

    const fetchCollections = async () => {
        try {
            const collections = await api.admin.getCollections();
            setCollections(collections);
        } catch (error) {
            console.error('Error fetching collections:', error);
        }
    };

    const fetchDocuments = async () => {
        try {
            const response = await api.admin.getDocuments(selectedCollection, currentPage);
            setDocuments(response.docs);
            setTotalPages(response.pages);
        } catch (error) {
            console.error('Error fetching documents:', error);
        }
    };

    const handleUpdate = async (doc: any) => {
        try {
            console.log('Updating doc:', editingDoc);
            const updateData = { ...editingDoc };

            Object.keys(updateData).forEach(key => {
                if (updateData[key] === undefined || updateData[key] === null) {
                    delete updateData[key];
                }
            });

            await api.admin.updateDocument(selectedCollection, doc._id, updateData);
            setEditingDoc(null);
            fetchDocuments();
        } catch (error: any) {
            console.error('Error updating document:', error);
            alert(error.response?.data?.message || 'Error updating document');
        }
    };

    const handleDelete = async (doc: any) => {
        if (!doc._id) {
            alert('Invalid document ID');
            return;
        }

        if (window.confirm('Are you sure you want to delete this document?')) {
            try {
                await api.admin.deleteDocument(selectedCollection, doc._id);
                fetchDocuments();
            } catch (error: any) {
                console.error('Error deleting document:', error);
                alert(error.response?.data?.message || 'Error deleting document');
            }
        }
    };

    const getFieldConfig = (collectionName: string): FieldType[] => {
        console.log('Getting field config for:', collectionName);
        switch (collectionName) {
            case "users":
                return [
                    { name: '_id', type: 'string' },
                    { name: 'username', type: 'string' },
                    { name: 'email', type: 'string' },
                    { name: 'createdAt', type: 'date' },
                    { name: 'updatedAt', type: 'date' },
                ];
            case 'cardpacks':
                return [
                    { name: '_id', type: 'string' },
                    { name: 'name', type: 'string' },
                    { name: 'isPublic', type: 'boolean' },
                    { name: 'isOriginal', type: 'boolean' },
                    { name: 'createdBy', type: 'string' },
                    { name: 'blackCardCount', type: 'number' },
                    { name: 'whiteCardCount', type: 'number' },
                    { name: 'usageCount', type: 'number' },
                    { name: 'rating', type: 'number' },
                    { name: 'createdAt', type: 'date' },
                    { name: 'imageUrl', type: 'image' },
                    { name: '__v', type: 'version' },
                ];
            default:
                return [];
        }
    };

    const renderEditField = (field: FieldType, value: any, onChange: (value: any) => void) => {
        const isReadOnly = field.name === '_id' || field.name === '__v';

        switch (field.type) {
            case 'boolean':
                return (
                    <select
                        value={value?.toString()}
                        onChange={(e) => onChange(e.target.value === 'true')}
                        className={styles.editInput}
                        disabled={isReadOnly}
                    >
                        <option value="true">True</option>
                        <option value="false">False</option>
                    </select>
                );
            case 'number':
                return (
                    <input
                        type="number"
                        value={value || 0}
                        onChange={(e) => onChange(Number(e.target.value))}
                        className={styles.editInput}
                        disabled={isReadOnly}
                    />
                );
            case 'date':
                return (
                    <input
                        type="datetime-local"
                        value={value ? new Date(value).toISOString().slice(0, 16) : ''}
                        onChange={(e) => onChange(new Date(e.target.value).toISOString())}
                        className={styles.editInput}
                        disabled={isReadOnly}
                    />
                );
            case 'image':
                return (
                    <div className={styles.imageField}>
                        <input
                            type="text"
                            value={value || ''}
                            onChange={(e) => onChange(e.target.value)}
                            className={styles.editInput}
                            disabled={isReadOnly}
                        />
                        {value && (
                            <img
                                src={value}
                                alt="Preview"
                                className={styles.imagePreview}
                            />
                        )}
                    </div>
                );
            case 'version':
                return (
                    <input
                        type="number"
                        value={value || 0}
                        className={styles.editInput}
                        disabled={true}
                    />
                );
            default:
                return (
                    <input
                        type="text"
                        value={value || ''}
                        onChange={(e) => onChange(e.target.value)}
                        className={styles.editInput}
                        disabled={isReadOnly}
                    />
                );
        }
    };

    return (
        <div className={styles.adminPanel}>
            <div className={styles.adminHeader}>
                <h2 className={styles.adminTitle}>Admin Dashboard</h2>
                <select
                    className={styles.collectionSelect}
                    onChange={(e) => setSelectedCollection(e.target.value)}
                >
                    <option value="">Select Collection</option>
                    {collections.map(col => (
                        <option key={col} value={col}>{col}</option>
                    ))}
                </select>
            </div>

            {selectedCollection && (
                <div>
                    <table className={styles.documentsTable}>
                        <thead>
                            <tr className={styles.tableHeader}>
                                <th>ID</th>
                                <th>Data</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {documents.map(doc => (
                                <tr key={doc._id} className={styles.tableRow}>
                                    <td className={`${styles.tableCell} ${styles.idCell}`}>
                                        {doc._id}
                                    </td>
                                    <td className={`${styles.tableCell} ${styles.dataCell}`}>
                                        {editingDoc?._id === doc._id ? (
                                            <div className={styles.editFields}>
                                                {getFieldConfig(selectedCollection).map(field => (
                                                    <div key={field.name} className={styles.fieldRow}>
                                                        <label className={styles.fieldLabel}>{field.name}:</label>
                                                        {renderEditField(
                                                            field,
                                                            editingDoc[field.name],
                                                            (value) => {
                                                                console.log(`Updating ${field.name}:`, value);
                                                                setEditingDoc({
                                                                    ...editingDoc,
                                                                    [field.name]: value
                                                                });
                                                            }
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            <pre className={styles.dataPre}>
                                                {JSON.stringify(doc, null, 2)}
                                            </pre>
                                        )}
                                    </td>
                                    <td className={styles.tableCell}>
                                        <div className={styles.actionButtons}>
                                            {editingDoc?._id === doc._id ? (
                                                <>
                                                    <button
                                                        className={styles.saveButton}
                                                        onClick={() => handleUpdate(doc)}
                                                    >
                                                        Save
                                                    </button>
                                                    <button
                                                        className={styles.cancelButton}
                                                        onClick={() => setEditingDoc(null)}
                                                    >
                                                        Cancel
                                                    </button>
                                                </>
                                            ) : (
                                                <>
                                                    <button
                                                        className={styles.editButton}
                                                        onClick={() => {
                                                            const docCopy = JSON.parse(JSON.stringify(doc));
                                                            console.log('Setting editingDoc:', docCopy);
                                                            setEditingDoc(docCopy);
                                                        }}
                                                    >
                                                        Edit
                                                    </button>
                                                    <button
                                                        className={styles.deleteButton}
                                                        onClick={() => handleDelete(doc)}
                                                    >
                                                        Delete
                                                    </button>
                                                </>
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <div className={styles.pagination}>
                        <button
                            className={styles.paginationButton}
                            disabled={currentPage === 1}
                            onClick={() => setCurrentPage(p => p - 1)}
                        >
                            Previous
                        </button>
                        <span className={styles.pageInfo}>
                            Page {currentPage} of {totalPages}
                        </span>
                        <button
                            className={styles.paginationButton}
                            disabled={currentPage === totalPages}
                            onClick={() => setCurrentPage(p => p + 1)}
                        >
                            Next
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Admin; 