import React, { useState, useRef, useMemo, useEffect } from 'react';
import styles from '../styles/GameLayout.module.css';
import { Socket } from 'socket.io-client';
import { Card, Player, BlackCard } from '../types/game';
import Confetti from 'react-confetti';
import modernStyles from '../styles/ModernUI.module.css';
import CardLayout, { CardType } from './Card';
import { useNavigate } from 'react-router-dom';


interface Notification {
    title: string;
    message: string;
    reason?: string;
    id: number;
}

interface GameLayoutProps {

    allCardsRevealed: boolean; // Add this new prop
    blackCard: BlackCard | null;
    cardCzarId: string | null;
    currentPlayerId: string | null;
    currentPlayerName: string;
    deckImageUrls: string[];
    gameId: string;
    gameName: string;
    gamePhase: string;
    gameStage: React.ReactNode;
    gameStatus: React.ReactNode;
    isGameCreator: boolean;
    notifications: Notification[];
    onlineUsers: string[];
    onRestartGame: () => void;
    onReturnToLobby: () => void;
    onRevealCard: (playerId: string) => void;
    onSelectWinner: (winningPlayerId: string) => void;
    onStartGame: () => void;
    playCards: (cardIds: string[]) => void;
    playedCards: { [playerId: string]: Card | Card[] };
    playerCount: number;
    playerHand: Card[];
    playerSlots: string[];
    requiredCards: number;
    revealedCards: string[];
    roundWinner: Player | null;
    scoreboard: {
        players: {
            id: string;
            name: string;
            score: number;
            isCurrentPlayer: boolean;
            isCardCzar: boolean;
        }[];
        winningScore: number;
    };
    socket: Socket;
    winner: string | null;
}

const GameLayout: React.FC<GameLayoutProps> = (props) => {



    const navigate = useNavigate();
    const [isScoreboardOpen, setIsScoreboardOpen] = useState(false);
    const [selectedCardIds, setSelectedCardIds] = useState<string[]>([]);
    const cardsPerPlayer = useMemo(() => Math.ceil(props.requiredCards / (props.playerCount - 1)), [props.requiredCards, props.playerCount]);
    const [hasPlayedCards, setHasPlayedCards] = useState(false);
    const cardRefs = useRef<(HTMLDivElement | null)[]>([]);
    const [showConfetti, setShowConfetti] = useState(false);
    const [visibleNotifications, setVisibleNotifications] = useState<Notification[]>([]);
    const [showWinnerModal, setShowWinnerModal] = useState(false);
    const [selectedWinningCard, setSelectedWinningCard] = useState<string | null>(null);
    const [filteredPlayers, setFilteredPlayers] = useState<string[]>([]);
    const [showResults, setShowResults] = useState(false);
    const [isSearching, setIsSearching] = useState(false);
    const playedCardsContainerRef = useRef<HTMLDivElement>(null);
    const [isFullscreen, setIsFullscreen] = useState(false)

    const debounce = (func: Function, wait: number) => {
        let timeout: NodeJS.Timeout;
        return (...args: any[]) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => func(...args), wait);
        };
    };

    const debouncedSearch = useMemo(
        () =>
            debounce((searchTerm: string) => {
                console.log("searchTerm", searchTerm)
                setIsSearching(false);

                // Filter based on search term
                const filtered = props.onlineUsers.filter(user =>
                    user.toLowerCase().includes(searchTerm.toLowerCase()) &&
                    user !== props.currentPlayerName &&
                    !props.scoreboard.players.some(p => p.name === user)
                );
                setFilteredPlayers(filtered);

            }, 300),
        [props.onlineUsers, props.scoreboard.players, props.currentPlayerName]
    );

    // When component mounts or when online users change, initialize the filtered list
    useEffect(() => {
        const initialPlayers = props.onlineUsers.filter(user =>
            user !== props.currentPlayerName &&
            !props.scoreboard.players.some(p => p.name === user)
        );
        setFilteredPlayers(initialPlayers);
        //eslint-disable-next-line
    }, [props.onlineUsers]);

    useEffect(() => {
        if (props.gamePhase === 'roundWinner') {
            console.log("wasd round winner")
            setShowWinnerModal(true);
            setTimeout(() => setShowWinnerModal(false), 5000);
            setTimeout(() => {
                setIsScoreboardOpen(false);
                setHasPlayedCards(false);
            }, 5000);
        }
        console.log("wasd game phase", props.gamePhase)
    }, [props.gamePhase]);

    useEffect(() => {
        if (props.roundWinner?.id === props.currentPlayerId) {
            setShowConfetti(true);
            setTimeout(() => setShowConfetti(false), 5000); // Stop confetti after 5 seconds
        }
    }, [props.roundWinner, props.currentPlayerId]);

    useEffect(() => {
        console.log("wasd playerHand", props.playerHand);
        adjustFontSize();
    }, [props.playerHand]);


    useEffect(() => {
        console.log("onlineUsers in GameLayout:", props.onlineUsers);
    }, [props.onlineUsers]);

    useEffect(() => {
        // Check for new notifications
        const newNotifications = props.notifications.filter(
            notification => !visibleNotifications.some(vn => vn.id === notification.id)
        );

        if (newNotifications.length > 0) {
            setVisibleNotifications(prevNotifications => [...prevNotifications, ...newNotifications]);

            // Set up timeouts to remove new notifications
            newNotifications.forEach(notification => {
                const duration = notification.reason ? 10000 : 3000;
                setTimeout(() => {
                    setVisibleNotifications(prevNotifications =>
                        prevNotifications.filter(n => n.id !== notification.id)
                    );
                }, duration);
            });
        }
        // eslint-disable-next-line
    }, [props.notifications]);

    useEffect(() => {
        // Only scroll if we're still revealing cards
        const totalPlayedCards = Object.keys(props.playedCards).length;
        if (props.revealedCards.length > 0 &&
            props.revealedCards.length < totalPlayedCards &&
            playedCardsContainerRef.current) {

            const lastRevealedCard = props.revealedCards[props.revealedCards.length - 1];
            const cardElement = playedCardsContainerRef.current.querySelector(
                `[data-player-id="${lastRevealedCard}"]`
            );

            if (cardElement && playedCardsContainerRef.current) {
                // Add a small delay to ensure the card has finished any animations
                setTimeout(() => {
                    // Calculate the scroll position
                    const container = playedCardsContainerRef.current;
                    if (!container) return;
                    const cardRect = cardElement.getBoundingClientRect();
                    const containerRect = container.getBoundingClientRect();
                    const scrollTop = cardRect.top + cardRect.height / 2 - containerRect.top - containerRect.height / 2;

                    container.scrollTo({
                        top: container.scrollTop + scrollTop,
                        behavior: 'smooth'
                    });
                }, 100);
            }
        }
    }, [props.revealedCards, props.playedCards]);


    useEffect(() => {
        const handleFullscreenChange = () => {
            setIsFullscreen(!!document.fullscreenElement)
        }

        document.addEventListener('fullscreenchange', handleFullscreenChange)
        return () => document.removeEventListener('fullscreenchange', handleFullscreenChange)
    }, [])


    const handleCardClick = (cardId: string) => {
        console.log("handleCardClick called with cardId:", cardId);
        console.log("Current selection:", selectedCardIds);
        console.log("Cards per player:", cardsPerPlayer);

        setSelectedCardIds(prev => {
            const newSelection = prev.includes(cardId)
                ? prev.filter(id => id !== cardId)
                : prev.length < cardsPerPlayer
                    ? [...prev, cardId]
                    : prev;
            console.log("New selection:", newSelection);
            return newSelection;
        });
    };

    const handlePlayCards = () => {
        console.log("cardClicked selectedCardIds", selectedCardIds)
        if (selectedCardIds.length === cardsPerPlayer) {
            props.playCards(selectedCardIds);
            setSelectedCardIds([]);
            setHasPlayedCards(true);
        }
    };




    const adjustFontSize = () => {
        cardRefs.current.forEach((cardRef) => {
            if (cardRef) {
                const content = cardRef.querySelector('.cardContent') as HTMLElement;
                if (content) {
                    const maxFontSize = 14;
                    const minFontSize = 8;
                    let low = minFontSize;
                    let high = maxFontSize;

                    while (low <= high) {
                        const mid = Math.floor((low + high) / 2);
                        content.style.fontSize = `${mid}px`;

                        if (content.scrollHeight <= cardRef.clientHeight && content.scrollWidth <= cardRef.clientWidth) {
                            low = mid + 1;
                        } else {
                            high = mid - 1;
                        }
                    }

                    content.style.fontSize = `${high}px`;
                }
            }
        });
    };

    const getPlayerNameById = (playerId: string): string => {
        const player = props.scoreboard.players.find(p => p.id === playerId);
        return player ? player.name : 'Unknown Player';
    };

    const toggleScoreboard = () => setIsScoreboardOpen(!isScoreboardOpen);








    const renderWinnerModal = () => {
        if (!showWinnerModal || !props.roundWinner) return null;

        const winningCards = props.playedCards[props.roundWinner.id];

        return (
            <div className={styles.winnerModal}>
                {showConfetti && <Confetti />}
                <div>
                    <h2>Round Winner</h2>
                    <p>{props.roundWinner.name} won this round!</p>
                </div>
                <div className={styles.winningCards}>
                    <div className={styles.blackCard}>
                        <h4>Black Card:</h4>
                        <p>{props.blackCard?.text || 'No black card available'}</p>
                    </div>
                    <div className={styles.winningWhiteCard}>
                        <h4>Winning Card:</h4>
                        {winningCards ? (
                            Array.isArray(winningCards) ? (
                                winningCards.map((card, index) => (
                                    <p key={index}>{card.text}</p>
                                ))
                            ) : (
                                <p>{winningCards.text}</p>
                            )
                        ) : (
                            <p>No winning card available</p>
                        )}
                        <p className={styles.winningPlayerName}>{props.roundWinner.name}</p>
                    </div>
                </div>
            </div>
        );
    };

    const renderScoreboard = () => {
        if (!isScoreboardOpen) return null;

        return (
            <div className={styles.scoreboardOverlay} onClick={toggleScoreboard}>
                <div className={styles.scoreboardModal} onClick={(e) => e.stopPropagation()}>

                    {props.roundWinner?.id === props.currentPlayerId ? (
                        <h2>You won this round!</h2>
                    ) : (
                        <h2>Winner of round: {props.roundWinner?.name}</h2>
                    )}

                    <h2>Scoreboard</h2>
                    <table className={styles.scoreTable}>
                        <thead>
                            <tr>
                                <th>Player</th>
                                <th>Score</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.playerSlots.map((playerName, index) => (
                                <tr key={index}>
                                    <td className={props.roundWinner?.name === playerName ? styles.roundWinner : ''}>
                                        {playerName} {props.roundWinner?.name === playerName ? '(Winner)' : ''}
                                    </td>
                                    <td className={props.roundWinner?.name === playerName ? styles.roundWinner : ''}>
                                        {props.scoreboard.players.find(player => player.name === playerName)?.score || 0}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <button className={styles.closeButton} onClick={toggleScoreboard}>Close</button>
                </div>
            </div>
        );
    };



    const renderPlayedCards = () => {
        const cardCzarName = getPlayerNameById(props.cardCzarId || '');
        const isCardCzar = props.cardCzarId === props.currentPlayerId;
        const playedCards = props.playedCards;

        if (!playedCards || Object.keys(playedCards).length === 0) {
            return null;
        }

        const handleCrownWinner = () => {
            if (selectedWinningCard && isCardCzar) {
                props.onSelectWinner(selectedWinningCard);
                setSelectedWinningCard(null);
            }
        };

        return (
            <div className={modernStyles.modalOverlay}>
                <div className={modernStyles.playedCardsModal}>
                    <div className={modernStyles.modalHeader}>
                        {isCardCzar ? (
                            <h2 className={modernStyles.modalTitle}>
                                {!props.allCardsRevealed
                                    ? "You are the Card Czar. Reveal the cards!"
                                    : "Select the funniest card and crown the winner!"}
                            </h2>
                        ) : (
                            <h2 className={modernStyles.modalTitle}>
                                {cardCzarName} is choosing the funniest card!
                            </h2>
                        )}
                    </div>
                    <div className={modernStyles.blackCard}>
                        {props.blackCard && (
                            <CardLayout
                                card={props.blackCard}
                                isRevealed={true}
                                packImg={props.deckImageUrls[0]}
                            />
                        )}
                    </div>

                    <div className={modernStyles.playedCardsContainer} ref={playedCardsContainerRef}>
                        {Object.entries(props.playedCards).map(([playerId, cards], index) => {
                            const isRevealed = props.revealedCards.includes(playerId);
                            const canSelect = isCardCzar && isRevealed && props.allCardsRevealed;
                            const isSelected = selectedWinningCard === playerId;

                            if (Array.isArray(cards)) {
                                return cards.map((card, cardIndex) => (
                                    <div
                                        key={`${playerId}-${cardIndex}`}
                                        data-player-id={playerId}
                                        className={`${modernStyles.playedCardSlot}`}
                                        style={{ animationDelay: `${index * 0.1 + cardIndex * 0.05}s` }}
                                    >
                                        <CardLayout
                                            card={card as CardType}
                                            isRevealed={isRevealed}
                                            isSelectable={canSelect}
                                            isSelected={isSelected}
                                            packImg={props.deckImageUrls[0]}
                                            onClick={() => {
                                                if (canSelect) {
                                                    setSelectedWinningCard(playerId);
                                                }
                                            }}
                                        />
                                    </div>
                                ));
                            }

                            return (
                                <div
                                    key={playerId}
                                    data-player-id={playerId}
                                    className={`${modernStyles.playedCardSlot}`}
                                    style={{ animationDelay: `${index * 0.1}s` }}
                                >
                                    <CardLayout
                                        card={cards as CardType}
                                        isRevealed={isRevealed}
                                        isSelectable={canSelect}
                                        isSelected={isSelected}
                                        packImg={props.deckImageUrls[0]}
                                        onClick={() => {
                                            if (canSelect) {
                                                setSelectedWinningCard(playerId);
                                            }
                                        }}
                                    />
                                </div>
                            );
                        })}
                    </div>

                    <div className={modernStyles.modalFooter}>
                        {isCardCzar && !props.allCardsRevealed && (
                            <button
                                className={`${modernStyles.crownButton} ${modernStyles.slideInUp}`}
                                onClick={() => {
                                    const unrevealedPlayerIds = Object.keys(props.playedCards)
                                        .filter(id => !props.revealedCards.includes(id));
                                    if (unrevealedPlayerIds.length > 0) {
                                        props.onRevealCard(unrevealedPlayerIds[0]);
                                    }
                                }}
                            >
                                🎭 Reveal Next Card
                            </button>
                        )}

                        {isCardCzar && props.allCardsRevealed && (
                            <button
                                className={`${modernStyles.crownButton} ${modernStyles.slideInUp}`}
                                onClick={handleCrownWinner}
                                disabled={!selectedWinningCard}
                            >
                                👑 Crown the Winner!
                            </button>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    const renderLobbyPhase = () => (
        <>
            <div className={modernStyles.modernCardHeader}>
                <h2 className={modernStyles.modernCardTitle}>Game Controls</h2>
            </div>
            <div className={modernStyles.modernCardContent}>
                <p className={modernStyles.modernText}>
                    Waiting for players... ({props.playerCount} players joined)
                </p>
                <button
                    className={modernStyles.modernButton}
                    onClick={props.onStartGame}
                    disabled={props.playerCount < 3}
                >
                    {props.playerCount < 3
                        ? 'Need at least 3 players to start'
                        : 'Start Game'}
                </button>
            </div>
        </>
    );

    const renderGamePhase = () => (
        <>
            <div className={modernStyles.modernCardHeader}>
                <h2 className={modernStyles.modernCardTitle}>Game Table: {props.gameName}</h2>
            </div>
            {props.blackCard && (
                <div className={modernStyles.modernBlackCard}>
                    {props.blackCard.text}
                </div>
            )}
        </>
    );

    const renderGameOverPhase = () => (
        <div className={modernStyles.modalOverlay}>
            <div className={modernStyles.playedCardsModal}>
                <div className={modernStyles.modalHeader}>
                    <h2 className={modernStyles.modalTitle}>
                        🏆 Game Over
                    </h2>
                </div>

                <div className={modernStyles.gameOverContent}>
                    <div className={modernStyles.gameOverWinnerBox}>
                        <p className={modernStyles.gameOverWinnerLabel}>
                            Winner of this game is:
                        </p>
                        <p className={modernStyles.gameOverWinnerName}>
                            {props.scoreboard.players.find(player => player.score === props.scoreboard.winningScore)?.name}
                        </p>
                    </div>
                </div>

                <div className={modernStyles.modalFooter}>
                    <button
                        onClick={() => navigate('/')}
                        className={`${modernStyles.modernButton} ${modernStyles.gameOverBackButton}`}
                    >
                        Back to Lobby
                    </button>
                    <button
                        onClick={() => {
                            props.onRestartGame();
                            setHasPlayedCards(false);
                        }}
                        className={`${modernStyles.modernButton} ${modernStyles.gameOverRematchButton}`}
                    >
                        Rematch
                    </button>
                </div>
            </div>
        </div>
    );

    const renderGameContent = () => {
        if (props.gamePhase === "lobby" && props.isGameCreator) {
            return renderLobbyPhase();
        }

        switch (props.gamePhase) {
            case 'lobby':
                return renderLobbyPhase();
            case 'playing':
                return renderGamePhase();
            case 'selection':
                return renderPlayedCards();
            case 'roundWinner':
                return renderWinnerModal();
            case 'gameOver':
                return renderGameOverPhase();
            default:
                return null;
        }
    };


    const toggleFullscreen = () => {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen()
        } else if (document.exitFullscreen) {
            document.exitFullscreen()
        }
    }

    return (
        <div className={modernStyles.modernLayout}>
            <main className={modernStyles.modernContent}>
                <div className={modernStyles.modernGrid}>


                    {/* Game Table Section */}
                    <div className={modernStyles.modernCard}>
                        {renderGameContent()}
                    </div>

                    {/* Players Section */}
                    <div className={modernStyles.modernCard}>
                        <div className={modernStyles.modernCardHeader}>
                            <h2 className={modernStyles.modernCardTitle}>Players</h2>
                            {/* TODO Add go fullscreen button    */}
                            <button
                                onClick={toggleFullscreen}
                                className={`${modernStyles.modernButton} ${modernStyles.ghostButton}`}
                            >
                                {isFullscreen ? (
                                    <div className={modernStyles.iconButton}>
                                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                            <path d="M8 3v3a2 2 0 0 1-2 2H3m18 0h-3a2 2 0 0 1-2-2V3m0 18v-3a2 2 0 0 1 2-2h3M3 16h3a2 2 0 0 1 2 2v3" />
                                        </svg>
                                        Exit Fullscreen
                                    </div>
                                ) : (
                                    <div className={modernStyles.iconButton}>
                                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                            <path d="M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3" />
                                        </svg>
                                        Fullscreen
                                    </div>
                                )}
                            </button>
                        </div>
                        <div className={modernStyles.modernPlayersList}>
                            {props.scoreboard.players.map((player) => (
                                <div key={player.id} className={modernStyles.modernPlayerItem}>
                                    <div className={modernStyles.modernPlayerInfo}>
                                        <div className={modernStyles.modernAvatar}>
                                            {player.name[0]}
                                        </div>
                                        <span>{player.name}</span>
                                    </div>
                                    <div className={modernStyles.modernPlayerStats}>
                                        <span className={modernStyles.modernScore}>
                                            Score: {player.score}
                                        </span>
                                        {player.isCardCzar && (
                                            <span className={modernStyles.modernBadge}>
                                                Card Czar
                                            </span>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>

                        {/* New Player Search Section */}
                        {props.gamePhase === "lobby" && (
                            <div className={modernStyles.modernSearchSection}>
                                <div className={modernStyles.searchContainer}>
                                    <input
                                        type="text"
                                        placeholder="Search players to invite..."
                                        className={modernStyles.modernSearchInput}
                                        onFocus={() => setShowResults(true)}
                                        onBlur={(e) => {
                                            setTimeout(() => setShowResults(false), 200);
                                        }}
                                        onChange={(e) => {
                                            setIsSearching(true);
                                            debouncedSearch(e.target.value);
                                        }}
                                    />
                                    <button
                                        className={modernStyles.searchButton}
                                        onClick={() => {
                                            const input = document.querySelector(`.${modernStyles.modernSearchInput}`) as HTMLInputElement;
                                            if (input) {
                                                input.focus();
                                            }
                                        }}
                                    >
                                        Search
                                    </button>
                                </div>

                                {showResults && (
                                    <div className={modernStyles.notificationDropdown}>
                                        <div className={modernStyles.notificationTitle}>
                                            {isSearching ? 'Searching...' : 'Online Players'}
                                        </div>
                                        <div className={modernStyles.notificationList}>
                                            {isSearching ? (
                                                <div className={modernStyles.searchLoading}>
                                                    <div className={modernStyles.searchLoadingDot}></div>
                                                    <div className={modernStyles.searchLoadingDot}></div>
                                                    <div className={modernStyles.searchLoadingDot}></div>
                                                </div>
                                            ) : filteredPlayers.length > 0 ? (
                                                filteredPlayers.map((player, index) => (
                                                    <div
                                                        key={player}
                                                        className={modernStyles.notificationItem}
                                                        style={{ animationDelay: `${index * 0.05}s` }}
                                                        onMouseDown={(e) => {
                                                            e.preventDefault();
                                                            if (window.confirm(`Invite ${player} to join the game?`)) {
                                                                props.socket.emit('invitePlayer', {
                                                                    gameId: props.gameId,
                                                                    playerName: player,
                                                                    inviterName: props.currentPlayerName
                                                                });
                                                            }
                                                        }}
                                                    >
                                                        <div className={modernStyles.modernPlayerInfo}>
                                                            <div className={modernStyles.modernAvatar}>
                                                                {player[0]}
                                                            </div>
                                                            <h4>{player}</h4>
                                                        </div>
                                                    </div>
                                                ))
                                            ) : (
                                                <div className={modernStyles.notificationItem}>
                                                    <p>No players found</p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>

                {/* Player Hand Section */}
                <div className={modernStyles.modernCard}>
                    <div className={modernStyles.modernCardHeader}>
                        <h2 className={modernStyles.modernCardTitle}>Your Hand</h2>
                    </div>
                    <div className={modernStyles.modernHandGrid}>
                        {props.playerHand.map((card) => {
                            const isSelected = selectedCardIds.includes(card.id);
                            const isDisabled = hasPlayedCards || props.cardCzarId === props.currentPlayerId;


                            return (
                                <CardLayout
                                    key={card.id}
                                    card={card}
                                    isRevealed={true}
                                    isSelectable={!isDisabled}
                                    isSelected={isSelected}
                                    onClick={() => {
                                        console.log("Card clicked in hand:", card.id);
                                        if (!isDisabled) {
                                            handleCardClick(card.id);
                                        }
                                    }}
                                />
                            );
                        })}
                    </div>
                    <div className={modernStyles.modernFooter}>
                        <button
                            className={modernStyles.modernButton}
                            onClick={handlePlayCards}
                            disabled={selectedCardIds.length !== cardsPerPlayer}
                        >
                            Play Selected Cards ({selectedCardIds.length}/{cardsPerPlayer})
                        </button>
                    </div>

                </div>
            </main>

            {/* Keep existing modals and notifications with updated styles */}
            {renderScoreboard()}
            {/* {renderSelectionModal()} */}

            {visibleNotifications.map((notification) => (
                <div key={notification.id} className={modernStyles.modernNotification}>
                    <div className={modernStyles.modernCardTitle}>{notification.title}</div>
                    <div>{notification.message}</div>
                    {notification.reason && (
                        <div className={modernStyles.modernScore}>{notification.reason}</div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default GameLayout;
