// OtherGames.tsx

import { useEffect, useState } from 'react'
import styles from '../../styles/OtherGames.module.css';
import { CardPack, GameInfo } from '../../types/game';
import { api } from '../../services/api';
import CreateGameModal from './CreateGameModal';

interface OtherGamesProps {
    games: GameInfo[];
    onJoinGame: (gameId: string) => void;
    username: string;
    onDeleteGame: (gameId: string) => void;
    myGames: boolean;
}

function OtherGames({ games, onJoinGame, username, onDeleteGame, myGames }: OtherGamesProps) {
    console.log("games", games);
    const [searchName, setSearchName] = useState('');

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [availablePacks, setAvailablePacks] = useState<CardPack[]>([]);

    const filteredGames = games.filter(game =>
        game.gameName.toLowerCase().includes(searchName.toLowerCase())
    );
    console.log("filteredGames", filteredGames);
    useEffect(() => {

        fetchPacks();
    }, []);

    useEffect(() => {
        console.log("games:", games);
    }, [games]);

    const fetchPacks = async () => {
        try {
            const packs = await api.getAvailablePacks();
            console.log("availablePacks in MyGames:", packs);
            setAvailablePacks(packs);
        } catch (error) {
            console.error('Error fetching card packs:', error);
        }
    };
    const handleCreateGame = async (gameSettings: {
        gameName: string,
        winningScore: number,
        selectedBlackPacks: CardPack[],
        selectedWhitePacks: CardPack[],
        botCount: number
    }) => {
        try {
            console.log("Creating game with packs:", {
                black: gameSettings.selectedBlackPacks,
                white: gameSettings.selectedWhitePacks
            });

            const { gameId, creatorId, playerId } = await api.createGame(
                username,
                gameSettings.gameName,
                gameSettings.winningScore,
                gameSettings.selectedBlackPacks,
                gameSettings.selectedWhitePacks,
                gameSettings.botCount
            );

            localStorage.setItem(`playerId_${gameId}`, playerId);
            localStorage.setItem(`creatorId_${gameId}`, creatorId);

            console.log(`Local storage set for game ${gameId}`);
            onJoinGame(gameId);
        } catch (error) {
            console.error('Error creating game:', error);
        } finally {
            setIsModalOpen(false);
        }
    };

    // Add this function to check if user was in the game
    const wasInGame = (gameId: string) => {
        return localStorage.getItem(`playerId_${gameId}`) !== null;
    };

    return (
        <>
            <form onSubmit={(e) => { e.preventDefault() }} className={styles.searchForm}>
                <input
                    type="text"
                    value={searchName}
                    onChange={(e) => setSearchName(e.target.value)}
                    placeholder="Enter Game Name"
                    className={styles.searchInput}
                />
                <button type="submit" className={styles.searchButton}>Search</button>
            </form>

            {myGames && (
                <button className={styles.createNewGameButton}
                    onClick={() => setIsModalOpen(true)}
                >
                    Create New Game
                </button>
            )}
            {filteredGames.length === 0 ? (
                <p>No games found.</p>
            ) : (
                <div className={styles.gameCards}>
                    {filteredGames.map(game => (
                        <div key={game.gameId} className={styles.gameCard}>
                            <h3>Game Name: {game.gameName}</h3>
                            <p>Creator: {game.creatorName}</p>
                            <p>Players: {game.playerCount}</p>
                            <p>Phase: {game.phase}</p>
                            <div className={styles.buttonContainer}>
                                {game.phase === 'lobby' && !myGames && (
                                    <button
                                        className={styles.joinButton}
                                        onClick={() => onJoinGame(game.gameId)}
                                    >
                                        Join Game
                                    </button>
                                )}
                                {wasInGame(game.gameId) && myGames && (
                                    <button
                                        className={styles.joinButton}
                                        onClick={() => onJoinGame(game.gameId)}
                                    >
                                        Rejoin Game
                                    </button>
                                )}
                                {game.creatorName === username && (
                                    <button
                                        className={styles.deleteButton}
                                        onClick={() => onDeleteGame(game.gameId)}
                                    >
                                        Delete Game
                                    </button>
                                )}
                            </div>
                        </div>
                    ))}


                </div>
            )}
            <CreateGameModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onCreateGame={handleCreateGame}
                availablePacks={availablePacks}
            />
        </>
    )
}

export default OtherGames