import React, { createContext, useState, useContext, useEffect } from 'react';
import { api } from '../services/api';

interface AuthContextType {
    isAuthenticated: boolean;
    username: string | null;
    userId: string | null;
    token: string | null;
    login: (username: string, password: string) => Promise<void>;
    signup: (username: string, email: string, password: string) => Promise<void>;
    joinWithoutSignup: (username: string) => Promise<void>;
    logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [username, setUsername] = useState<string | null>(null);
    const [token, setToken] = useState<string | null>(null);

    useEffect(() => {
        const storedToken = localStorage.getItem('token');
        const storedUsername = localStorage.getItem('username');


        if (storedToken && storedUsername) {
            try {
                // Check if token is valid JWT
                const parts = storedToken.split('.');
                if (parts.length !== 3) {
                    throw new Error('Invalid token format');
                }

                const payload = JSON.parse(atob(parts[1]));

                // Check expiration
                if (payload.exp && Date.now() >= payload.exp * 1000) {
                    throw new Error('Token expired');
                }

                setIsAuthenticated(true);
                setUsername(storedUsername);
                setToken(storedToken);
            } catch (error) {
                console.error('Token validation error:', error);
                // Clear invalid token
                localStorage.removeItem('token');
                localStorage.removeItem('username');
                setIsAuthenticated(false);
                setUsername(null);
                setToken(null);
            }
        }
    }, []);

    const login = async (username: string, password: string) => {
        const response = await api.login(username, password);
        const newToken = `auth_${response.token}`;
        localStorage.setItem('token', newToken);
        localStorage.setItem('username', username);
        setIsAuthenticated(true);
        setUsername(username);
        setToken(newToken);
    };

    const signup = async (username: string, email: string, password: string) => {
        const response = await api.signup(username, email, password);
        const newToken = `auth_${response.token}`;
        localStorage.setItem('token', newToken);
        localStorage.setItem('username', username);
        setIsAuthenticated(true);
        setUsername(username);
        setToken(newToken);
    };

    const joinWithoutSignup = async (username: string) => {
        const response = await api.joinWithoutSignup(username);
        const newToken = `temp_${response.token}`;
        localStorage.setItem('token', newToken);
        localStorage.setItem('username', username);
        setIsAuthenticated(false);
        setUsername(username);
        setToken(newToken);
    };

    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('username');
        setIsAuthenticated(false);
        setUsername(null);
        setToken(null);
    };

    return (
        <AuthContext.Provider value={{
            isAuthenticated,
            username,
            userId: null,
            token,
            login,
            signup,
            joinWithoutSignup,
            logout
        }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
