import React, { useState, useEffect, useRef } from 'react';

import { useTheme } from '../contexts/ThemeContext';
import { useAuth } from '../contexts/AuthContext';
import styles from '../styles/Nav.module.css';
import { Socket } from 'socket.io-client';
import { useNavigate } from 'react-router-dom';
import { Moon, Sun, Bell, User, ChevronDown } from 'lucide-react';
import cuteSettings from '../resources/icons/cute-settings-icon.svg';
import cuteProfile from '../resources/icons/cute-user-settings.svg';
import cuteLogoutClosed from '../resources/icons/cute-logout-door-icon.svg';
import cuteLogoutOpen from '../resources/icons/DoorOpen.svg';
import HUHLogo from '../resources/icons/HAHLogoCuterNStroke.svg';
import cuteDeck from '../resources/icons/Deck.svg';

interface NavProps {
    socket: Socket;
}

interface GameInvite {
    id: string;
    from: string;
    gameId: string;
    playerId: string;
    time: string;
    _id: string;
}

const Nav: React.FC<NavProps> = ({ socket }) => {

    const navigate = useNavigate();
    const [notifications, setNotifications] = useState<GameInvite[]>([]);
    const [showNotifications, setShowNotifications] = useState(false);
    const [showUserMenu, setShowUserMenu] = useState(false);
    const { username, isAuthenticated, logout } = useAuth();
    const notificationRef = useRef<HTMLDivElement>(null);
    const userMenuRef = useRef<HTMLDivElement>(null);
    const currentPlayerId = localStorage.getItem('playerId');
    const [isLogoutHovered, setIsLogoutHovered] = useState(false);
    const { theme, toggleTheme } = useTheme();

    useEffect(() => {
        // Fetch existing notifications on mount

        if (currentPlayerId) {
            fetchNotifications();
        }

        // Listen for new notifications
        socket.on('gameInvite', async (invite: GameInvite) => {
            console.log("Game invite received:", invite);
            setNotifications(prev => [...prev, invite]);
            // No need to save here as it's already saved on the server
        });

        return () => {
            socket.off('gameInvite');
        };
        //eslint-disable-next-line
    }, [currentPlayerId, socket]);

    useEffect(() => {

        fetchNotifications();
        function handleClickOutside(event: MouseEvent) {
            if (notificationRef.current && !notificationRef.current.contains(event.target as Node)) {
                setShowNotifications(false);
            }
            if (userMenuRef.current && !userMenuRef.current.contains(event.target as Node)) {
                setShowUserMenu(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);

        console.log("username", username);
        return () => document.removeEventListener('mousedown', handleClickOutside);
        //eslint-disable-next-line
    }, []);


    const fetchNotifications = async () => {
        try {
            if (!username) return; // Only fetch if we have a username

            console.log(`Fetching notifications for username: ${username}`);
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/notifications/user/${username}`);

            if (!response.ok) {
                throw new Error('Failed to fetch notifications');
            }
            const data = await response.json();
            // Transform the data to match your notification format
            const transformedNotifications = data.map((notification: any) => ({
                id: notification.content.id || notification._id,
                from: notification.content.from,
                gameId: notification.content.gameId,
                playerId: notification.content.playerId,
                time: notification.content.time || notification.createdAt,
                _id: notification._id
            }));
            setNotifications(transformedNotifications);
        } catch (error) {
            console.error('Error fetching notifications:', error);
        }
    };

    const handleLogout = () => {
        logout();
    };

    const handleChangeUser = () => {
        logout();
    };

    const handleAcceptInvite = async (gameId: string, inviteId: string, playerId: string) => {
        try {
            // Mark notification as read using the MongoDB _id
            const notification = notifications.find(n => n.id === inviteId);
            if (notification && notification._id) {
                await fetch(`${process.env.REACT_APP_API_URL}/api/notifications/${notification._id}/read`, {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' }
                });
            }

            // Remove the notification from local state
            setNotifications(prev => prev.filter(n => n.id !== inviteId));

            // Store the playerId from the invite
            localStorage.setItem(`playerId_${gameId}`, playerId);
            localStorage.setItem(`playerName_${gameId}`, username || '');

            // Emit socket event to join the game room
            socket.emit('acceptGameInvite', {
                gameId,
                playerId
            });

            // Navigate to the game
            navigate(`/${gameId}`, { state: { username } });
        } catch (error) {
            console.error('Error accepting game invite:', error);
        }
    };

    const handleDeclineInvite = async (inviteId: string) => {
        try {
            // Mark notification as read using the MongoDB _id
            const notification = notifications.find(n => n.id === inviteId);
            if (notification && notification._id) {
                await fetch(`${process.env.REACT_APP_API_URL}/api/notifications/${notification._id}/read`, {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' }
                });
            }

            // Remove the notification from local state
            setNotifications(prev => prev.filter(n => n.id !== inviteId));

            socket.emit('declineGameInvite', { inviteId });
        } catch (error) {
            console.error('Error declining game invite:', error);
        }
    };

    return (
        <div className={styles.container}>
            <header className={styles.header}>
                <img src={HUHLogo} alt="HUH Logo" className={styles.logo} onClick={() => navigate('/')} />
                <div className={styles.headerActions}>
                    <button
                        className={`${styles.iconButton} ${styles.themeToggle}`}
                        onClick={toggleTheme}
                        aria-label="Toggle theme"
                    >
                        {theme === 'light' ? (
                            <div className={styles.iconWrapper}>
                                <Moon size={20} strokeWidth={2.5} />
                            </div>
                        ) : (
                            <div className={styles.iconWrapper}>
                                <Sun size={20} strokeWidth={2.5} />
                            </div>
                        )}
                    </button>
                    <div className={styles.notificationMenu} ref={notificationRef}>
                        <button
                            className={`${styles.iconButton} ${styles.notificationButton}`}
                            onClick={() => setShowNotifications(!showNotifications)}
                        >
                            <div className={styles.iconWrapper}>
                                <Bell size={20} strokeWidth={2.5} />
                                {notifications.length > 0 && (
                                    <span className={styles.notificationBadge}>
                                        {notifications.length}
                                    </span>
                                )}
                            </div>
                        </button>
                        {showNotifications && (
                            <div className={styles.notificationDropdown}>
                                <h3 className={styles.notificationTitle}>Notifications</h3>
                                <div className={styles.notificationList}>
                                    {notifications.length === 0 ? (
                                        <div className={styles.noNotifications}>No pending invites</div>
                                    ) : (
                                        notifications.map((invite, index) => (
                                            <div
                                                key={invite.id}
                                                className={styles.notificationItem}
                                                style={{ '--index': index } as React.CSSProperties}
                                            >
                                                <h4>Game Invite</h4>
                                                <p>{invite.from} invited you to join their game</p>
                                                <span>{invite.time}</span>
                                                <div className={styles.inviteActions}>
                                                    <button
                                                        onClick={() => handleAcceptInvite(invite.gameId, invite.id, invite.playerId)}
                                                        className={styles.acceptButton}
                                                    >
                                                        Accept
                                                    </button>
                                                    <button
                                                        onClick={() => handleDeclineInvite(invite.id)}
                                                        className={styles.declineButton}
                                                    >
                                                        Decline
                                                    </button>
                                                </div>
                                            </div>
                                        ))
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className={styles.userMenu} ref={userMenuRef}>
                        <button
                            className={`${styles.iconButton} ${styles.userButton}`}
                            onClick={() => setShowUserMenu(!showUserMenu)}
                        >
                            <div className={styles.userButtonContent}>
                                <div className={styles.iconWrapper}>
                                    <User size={20} strokeWidth={2.5} />
                                </div>
                                <span className={styles.username}>{username || 'Guest'}</span>
                                <ChevronDown
                                    size={16}
                                    className={`${styles.chevron} ${showUserMenu ? styles.chevronUp : ''}`}
                                />
                            </div>
                        </button>
                        {showUserMenu && (
                            <div className={styles.dropdownContent}>
                                {isAuthenticated ? (
                                    <>
                                        <div className={styles.dropdownHeader}>My Account</div>
                                        {[
                                            {
                                                label: 'Profile', icon: <img src={cuteProfile} alt="profile" style={{
                                                    width: '32px',
                                                    height: '32px',
                                                    verticalAlign: 'middle',
                                                    transition: 'filter 0.2s ease'
                                                }} />
                                            },
                                            {
                                                label: 'Decks',
                                                icon: <img src={cuteDeck} alt="decks" style={{
                                                    width: '32px',
                                                    height: '32px',
                                                    verticalAlign: 'middle',
                                                    transition: 'filter 0.2s ease'
                                                }} />,
                                                action: () => navigate('/deck-management')
                                            },
                                            {
                                                label: 'Settings',
                                                icon: (
                                                    <img
                                                        src={cuteSettings}
                                                        alt="settings"
                                                        style={{
                                                            width: '32px',
                                                            height: '32px',
                                                            verticalAlign: 'middle',
                                                            filter: 'var(--icon-filter, none)', // Allows for hover effects
                                                            transition: 'filter 0.2s ease'
                                                        }}
                                                    />
                                                )
                                            },
                                            {
                                                label: 'Logout',
                                                icon: (
                                                    <img
                                                        src={isLogoutHovered ? cuteLogoutOpen : cuteLogoutClosed}
                                                        alt="logout"
                                                        style={{
                                                            width: '32px',
                                                            height: '32px',
                                                            verticalAlign: 'middle',
                                                            transition: 'filter 0.2s ease'
                                                        }}
                                                    />
                                                ),
                                                action: handleLogout
                                            }
                                        ].map((item, index) => (
                                            <button
                                                key={item.label}
                                                className={styles.dropdownItem}
                                                style={{ '--index': index } as React.CSSProperties}
                                                onClick={item.action}
                                                onMouseEnter={() => item.label === 'Logout' && setIsLogoutHovered(true)}
                                                onMouseLeave={() => item.label === 'Logout' && setIsLogoutHovered(false)}
                                            >
                                                {item.icon} {item.label}
                                            </button>
                                        ))}
                                    </>
                                ) : (
                                    <>
                                        <div className={styles.dropdownHeader}>Guest</div>
                                        <button
                                            className={styles.dropdownItem}
                                            style={{ '--index': 0 } as React.CSSProperties}
                                            onClick={handleChangeUser}
                                        >
                                            <img src={cuteProfile} alt="profile" style={{
                                                width: '32px',
                                                height: '32px',
                                                verticalAlign: 'middle',
                                                transition: 'filter 0.2s ease'
                                            }} /> Change User
                                        </button>
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </header>
        </div>
    );
};

export default Nav; 