import { io } from 'socket.io-client';

const SOCKET_URL = `${process.env.REACT_APP_API_URL}`;

console.log(`Initializing socket connection to: ${SOCKET_URL}`);

const socket = io(SOCKET_URL, {
    transports: ['websocket', 'polling'],
    autoConnect: true,
    reconnection: true,
    reconnectionAttempts: 5,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    timeout: 20000,
});

socket.on('connect', () => {
    console.log('Socket connected successfully');
});

socket.on('connect_error', (error) => {
    console.error('Socket connection error:', error);
});

export default socket;
