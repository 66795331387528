import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { api } from '../services/api';
import { GameState, Player, GameNotification } from '../types/game';
import styles from '../styles/GameLayout.module.css';
import socket from '../services/socket';

import GameLayout from './GameLayout';


const Game: React.FC = () => {
    const { gameId } = useParams<{ gameId?: string }>();
    const navigate = useNavigate();
    const [gameState, setGameState] = useState<GameState | null>(null);
    const [currentPlayerId, setCurrentPlayerId] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);
    const location = useLocation();
    const [currentPlayer, setCurrentPlayer] = useState<Player | null>(null);
    const [notifications, setNotifications] = useState<GameNotification[]>([]);
    const [onlineUsers, setOnlineUsers] = useState<string[]>([]);

    const logGameStateChange = useCallback((prevState: GameState | null, newState: GameState) => {
        if (!prevState) {
            console.log('Initial game state:', newState);
            return;
        }

        const changedProperties = Object.keys(newState).filter(key =>
            JSON.stringify(prevState[key as keyof GameState]) !== JSON.stringify(newState[key as keyof GameState])
        );

        if (changedProperties.length > 0) {
            console.log('Game state changed. Updated properties:', changedProperties);
            changedProperties.forEach(prop => {
                console.log(`${prop}:`, newState[prop as keyof GameState]);
            });
        }
    }, []);

    useEffect(() => {
        console.log('Socket object:', socket);

        if (gameId) {
            // Ensure socket is connected
            if (!socket.connected) {
                socket.connect();
            }

            console.log(`Attempting to join game: ${gameId}`);
            socket.emit('joinGame', gameId);

            // Re-announce presence when reconnecting
            socket.on('connect', () => {
                console.log('Socket connected in Game component');
                if (currentPlayer?.name) {
                    socket.emit('userOnline', currentPlayer.name);
                }
            });

            socket.on('onlineUsersUpdate', (users: string[]) => {
                console.log('Game received online users update:', users);
                console.log('Current player ID:', currentPlayerId);
                setOnlineUsers(users);
            });

            socket.on('disconnect', (reason) => {
                console.log('Socket disconnected:', reason);
            });

            socket.on('error', (error) => {
                console.error('Socket error:', error);
            });

            socket.on('gameStateUpdate', (updatedState: GameState) => {
                setGameState(prevState => {
                    logGameStateChange(prevState, updatedState);
                    return updatedState;
                });
                // Update to only use player names
                const playerNames = updatedState.players.map(player => player.name);
                setOnlineUsers(playerNames);
                console.log('Current player names:', playerNames);
            });

            socket.on('cardPlayed', (data) => {
                console.log('Card played:', data);
            });

            socket.on('notification', (notification: Notification) => {
                console.log('Received notification:', notification);
                setTimeout(() => {
                    setNotifications(prev => prev.slice(1));
                }, 3000);
            });

            socket.on('cardRevealed', ({ gameId: updatedGameId, revealedPlayerId }) => {
                if (updatedGameId === gameId) {
                    setGameState(prevState => {
                        if (!prevState) return prevState;
                        return {
                            ...prevState,
                            revealedCards: [...prevState.revealedCards, revealedPlayerId]
                        };
                    });
                }
            });

            return () => {
                socket.off('connect');
                socket.off('onlineUsersUpdate');
                console.log(`Leaving game: ${gameId}`);
                socket.emit('leaveGame', gameId);
                socket.off('gameStateUpdate');
                socket.off('cardPlayed');
                socket.off('notification');
                socket.off('cardRevealed');
                // Remove other listeners
            };
        }
        //eslint-disable-next-line
    }, [gameId, logGameStateChange]);

    useEffect(() => {
        if (gameId && currentPlayerId) {
            // Update online status when joining the game
            console.log('Updating online status - Player ID:', currentPlayerId);
            api.updateOnlineStatus(gameId, currentPlayerId, true);

            // Set up cleanup function to update status when leaving
            return () => {
                console.log('Cleaning up online status - Player ID:', currentPlayerId);
                api.updateOnlineStatus(gameId, currentPlayerId, false);
            };
        }
    }, [gameId, currentPlayerId]);

    const isCreator = gameState?.creatorId === currentPlayerId;
    // console.log(`isCreator: ${isCreator}, gameState.creatorId: ${gameState?.creatorId}, currentPlayerId: ${currentPlayerId}, currentPlayerName: ${currentPlayer?.name}`);

    useEffect(() => {
        if (!gameId) {
            return;
        }

        const joinOrRejoinGame = async () => {
            const storedPlayerId = localStorage.getItem(`playerId_${gameId}`);
            const storedPlayerName = localStorage.getItem(`playerName_${gameId}`);


            if (storedPlayerId && storedPlayerName) {
                await rejoinGame(storedPlayerId, storedPlayerName);
            } else if (location.state?.playerName) {
                await handleJoinGame(location.state.playerName);
            }
        };

        joinOrRejoinGame();

        const fetchGameState = async () => {
            try {
                if (!gameId || !currentPlayerId) {
                    return;
                }
                const state = await api.getGameState(gameId, currentPlayerId);
                console.log("wasd23 state", state);
                setGameState(state);
                setCurrentPlayer(state.players.find(p => p.id === currentPlayerId) || null);
            } catch (error) {
                console.error('Error fetching game state:', error);
                setError('Failed to fetch game state');
            }
        };
        // Only start fetching game state when we have a currentPlayerId
        if (currentPlayerId) {
            fetchGameState();
            const interval = setInterval(fetchGameState, 1000);
            return () => clearInterval(interval);
        }
        // eslint-disable-next-line 
    }, [gameId, location.state, currentPlayerId]);

    useEffect(() => {
        console.error("error", error)
    }, [error]);

    const rejoinGame = async (playerId: string, name: string) => {
        try {
            const gameState = await api.getGameState(gameId!, playerId);
            const player = gameState.players.find(p => p.id === playerId && p.name === name);
            if (player) {
                setCurrentPlayerId(playerId);
                setGameState(gameState);
            } else {
                await handleJoinGame(name);
            }
        } catch (error) {
            console.error('Failed to rejoin game:', error);
            localStorage.removeItem(`playerId_${gameId}`);
            localStorage.removeItem(`playerName_${gameId}`);
            setCurrentPlayerId(null);
        }
    };

    const handleJoinGame = async (name: string) => {
        if (currentPlayerId) {
            return;
        }

        try {
            const { playerId } = await api.joinGame(gameId!, name);
            setCurrentPlayerId(playerId);
            localStorage.setItem(`playerId_${gameId}`, playerId);
            localStorage.setItem(`playerName_${gameId}`, name);
        } catch (error: any) {
            console.error('Join game error:', error);
            setError(`Failed to join game: ${error.response?.data?.message || error.message}`);
        }
    };

    const handleStartGame = async () => {
        if (!gameId || !currentPlayerId) {
            setError('Invalid game ID or player ID');
            return;
        }

        const creatorId = localStorage.getItem(`creatorId_${gameId}`);
        if (!creatorId) {
            setError('You are not the game creator');
            return;
        }

        try {
            await api.startGame(gameId, creatorId);
            const updatedState = await api.getGameState(gameId, currentPlayerId);
            setGameState(updatedState);
        } catch (error) {
            console.error('Failed to start game:', error);
            setError('Failed to start game');
        }
    };


    const handleRestartGame = async () => {
        if (!gameId || !currentPlayerId) {
            setError('Invalid game ID or player ID');
            return;
        }

        const creatorId = localStorage.getItem(`creatorId_${gameId}`);
        if (!creatorId) {
            setError('You are not the game creator');
            return;
        }

        try {
            const updatedState = await api.restartGame(gameId, creatorId);
            setGameState(updatedState);
        } catch (error) {
            console.error('Failed to restart game:', error);
            setError('Failed to restart game');
        }
    };

    const handleReturnToLobby = async () => {
        if (!gameId || !currentPlayerId) {
            setError('Invalid game ID or player ID');
            return;
        }
        try {
            const updatedState = await api.leaveGame(gameId!, currentPlayerId!);
            setGameState(updatedState);
            navigate('/');
        } catch (error) {
            console.error('Failed to return to lobby:', error);
            setError('Failed to return to lobby');
        }
    };

    const handleWinnerSelection = async (winningPlayerId: string) => {
        if (!gameId || gameState?.cardCzar !== currentPlayerId) return;
        try {
            const updatedGameState = await api.selectWinner(gameId, winningPlayerId);
            console.log("Updated game state:", updatedGameState);
            setGameState(updatedGameState);
        } catch (error) {
            console.error('Error selecting winner:', error);
            setError('Failed to select winner');
        }
    };

    const getOrderedPlayerNames = () => {
        if (!gameState || !currentPlayerId) return [];

        const currentPlayerIndex = gameState.players.findIndex(p => p.id === currentPlayerId);
        if (currentPlayerIndex === -1) return [];
        const orderedPlayers = [
            ...gameState.players.slice(currentPlayerIndex + 1),
            ...gameState.players.slice(0, currentPlayerIndex),
            gameState.players[currentPlayerIndex]
        ];
        // Move the current player to the second position
        const currentPlayer = orderedPlayers.pop();
        if (currentPlayer) {
            orderedPlayers.splice(1, 0, currentPlayer);
        }
        // Return an array of player names
        return orderedPlayers.map(player => player.name);
    };

    const renderGameStatus = () => {
        if (!gameState) return null;
        const requiredCards = gameState.currentBlackCard?.blanks || 1;

        return (
            <div className={styles.gameStatus}>
                <h3>Game Status:</h3>
                <p>Game ID: {gameId}</p>
                <p>Round: {gameState.round}</p>
                <p>Current Card Czar: {gameState.players.find(p => p.id === gameState.cardCzar)?.name}</p>
                <p>Cards Played: {Object.keys(gameState.playedCards).length} / {requiredCards * (gameState.players.length - 1)}</p>
                <p>{gameState.phase === 'selection' ? 'Card Czar is selecting the winner!' : 'Players are playing cards.'}</p>
            </div>
        );
    };

    const handleRevealCard = async (playerId: string) => {
        console.log('handleRevealCard called for player', playerId);
        if (!gameId || !currentPlayerId || gameState?.cardCzar !== currentPlayerId) {
            console.log('Cannot reveal card: invalid state', { gameId, currentPlayerId, cardCzar: gameState?.cardCzar });
            return;
        }
        try {
            console.log('Attempting to reveal card');
            await api.revealCard(gameId, playerId);
            // We don't need to update the state here anymore, as it will be updated by the socket event
        } catch (error) {
            console.error('Error revealing card:', error);
            setError('Failed to reveal card');
        }
    };


    const calculateRequiredCards = () => {
        if (!gameState) return 0;
        const blanksPerPlayer = gameState.currentBlackCard?.blanks || 1;
        const playersExcludingCzar = gameState.players.length - 1; // Subtract 1 for the Card Czar
        return blanksPerPlayer * playersExcludingCzar;
    };

    const renderGameContent = () => {
        if (!gameState || !currentPlayerId) return null;


        switch (gameState.phase) {
            case 'lobby':
                return isCreator ? (
                    <button onClick={handleStartGame}>Start Game</button>
                ) : (
                    <p>Waiting for the game to start...</p>
                );
            case 'playing':
                return (
                    <>
                        {gameState.cardCzar !== currentPlayerId && <p>Choose your cards wisely.</p>}
                        {gameState.cardCzar === currentPlayerId && <p>You are the Card Czar for this round. Wait for the players to play their cards.</p>}

                    </>
                );
            case 'selection':
                return (
                    <>
                        {gameState.cardCzar === currentPlayerId && <p>Which card is the funniest?</p>}
                        {gameState.phase === 'selection' && gameState.cardCzar !== currentPlayerId && <p>Wait for the Card Czar to select a winner.</p>}

                    </>
                );
            case 'roundWinner':
                const roundWinner = gameState.players.find(p => p.name === gameState.lastWinner);
                return (
                    <>
                        <h3>Round Winner</h3>
                        <p>{roundWinner ? `${roundWinner.name} won this round!` : 'No winner for this round.'}</p>

                        <p>Next round will start soon...</p>
                    </>
                );
            case 'gameOver':
                const winner = gameState.players.find(p => p.id === gameState.winner);
                return (
                    <div>
                        <h2>Game Over!</h2>
                        <p>Winner: {winner ? winner.name : 'Unknown'}</p>
                        <p>Final Score: {winner ? winner.score : 0}</p>
                        <button onClick={() => navigate('/')}>Back to Lobby</button>
                        {isCreator && (
                            <button onClick={handleRestartGame}>Restart Game</button>
                        )}
                    </div>
                );
            default:
                return null;
        }
    };


    const renderScoreboard = () => {
        if (!gameState) return null;

        return {
            players: gameState.players.map((player) => ({
                id: player.id,
                name: player.name,
                score: player.score,
                isCurrentPlayer: player.id === currentPlayerId,
                isCardCzar: player.id === gameState.cardCzar
            })),
            winningScore: gameState.winningScore
        };
    };


    const handlePlayCards = async (cardIds: string[]) => {
        console.log("wasda cardIds", cardIds)
        if (!gameId || !currentPlayerId || !gameState) return;
        try {
            const updatedGameState = await api.playCard(gameId, currentPlayerId, cardIds);
            if (updatedGameState) {
                setGameState(updatedGameState);
            } else {
                console.error('No game state returned after playing cards');
            }
        } catch (error) {
            console.error('Error playing cards:', error);
            setError('Failed to play cards');
        }
    };
    return (
        <>
            <GameLayout
                allCardsRevealed={gameState ? Object.keys(gameState.playedCards).length === gameState.revealedCards.length : false}
                blackCard={gameState?.currentBlackCard || null}
                cardCzarId={gameState?.cardCzar || null}
                currentPlayerId={currentPlayerId}
                currentPlayerName={currentPlayer?.name || ''}
                deckImageUrls={gameState?.selectedWhiteCardPacks.map(pack => pack.imageUrl).filter((url): url is string => url !== undefined) || []}
                gameId={gameId || ''}
                gameName={gameState?.gameName || ''}
                gamePhase={gameState?.phase || ''}
                gameStage={renderGameContent()}
                gameStatus={renderGameStatus()}
                isGameCreator={isCreator}
                notifications={notifications}
                onlineUsers={onlineUsers}
                onRestartGame={handleRestartGame}
                onReturnToLobby={handleReturnToLobby}
                onRevealCard={() => handleRevealCard(currentPlayerId || '')}
                onSelectWinner={handleWinnerSelection}
                onStartGame={handleStartGame}
                playCards={handlePlayCards}
                playedCards={gameState?.playedCards || {}}
                playerCount={gameState?.players.length || 0}
                playerHand={currentPlayer?.hand || []}
                playerSlots={getOrderedPlayerNames()}
                requiredCards={calculateRequiredCards()}
                revealedCards={gameState?.revealedCards || []}
                roundWinner={gameState?.lastWinner ? gameState.players.find(p => p.name === gameState.lastWinner) || null : null}
                scoreboard={renderScoreboard() || { players: [], winningScore: 0 }}
                socket={socket}
                winner={gameState?.winner || null}
            />
        </>
    );
}

export default Game;
