import axios from 'axios';
import { GameState, GameInfo, Card, CardPack } from '../types/game';
import { ChatMessage } from '../types/game';

const API_URL = `${process.env.REACT_APP_API_URL}/api/game`;
const IMAGE_URL = `${process.env.REACT_APP_API_URL}/api/images`;
const AUTH_URL = `${process.env.REACT_APP_API_URL}/api/auth`;
const ADMIN_URL = `${process.env.REACT_APP_API_URL}/api/admin`;

// Create axios instance with authentication interceptor
const axiosInstance = axios.create();

// Add authentication token to all requests
axiosInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');

    if (token) {
        // Remove any 'auth_' or 'temp_' prefixes from the token
        const cleanToken = token.replace(/^(auth_|temp_)/, '');

        config.headers['Authorization'] = `Bearer ${cleanToken}`;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

// Add response interceptor to handle token expiration
axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        if (error.response?.status === 401) {
            // Clear local storage
            localStorage.removeItem('token');
            localStorage.removeItem('username');

            // Redirect to login page
            window.location.href = '/';
        }
        return Promise.reject(error);
    }
);

export const api = {
    // Game management endpoints
    createGame: async (
        playerName: string,
        gameName: string,
        winningScore: number,
        selectedBlackPacks: CardPack[],
        selectedWhitePacks: CardPack[],
        botCount: number
    ): Promise<{ gameId: string, creatorId: string, playerId: string }> => {
        const response = await axios.post(`${API_URL}/create`, {
            playerName,
            gameName,
            winningScore,
            blackCardPacks: selectedBlackPacks,
            whiteCardPacks: selectedWhitePacks,
            botCount
        });
        return response.data;
    },

    joinGame: async (gameId: string, playerName: string): Promise<{ playerId: string, gameState: GameState }> => {
        const response = await axios.post(`${API_URL}/${gameId}/join`, { playerName });
        return response.data;
    },

    leaveGame: async (gameId: string, playerId: string): Promise<any> => {
        const response = await axios.post(`${API_URL}/${gameId}/leave`, { playerId });
        return response.data;
    },

    deleteGame: async (gameId: string, creatorId: string): Promise<any> => {
        const response = await axios.delete(`${API_URL}/${gameId}/delete`, { data: { creatorId } });
        return response.data;
    },

    getGameState: async (gameId: string, playerId: string): Promise<GameState> => {
        const response = await axios.get(`${API_URL}/${gameId}`, {
            params: { playerId }
        });
        return response.data;
    },

    rejoinGame: async (gameId: string, playerId: string, playerName: string): Promise<void> => {
        await axios.post(`${API_URL}/${gameId}/rejoin`, { playerId, playerName });
    },

    startGame: async (gameId: string, creatorId: string): Promise<void> => {
        await axios.post(`${API_URL}/${gameId}/start`, { creatorId });
    },

    restartGame: async (gameId: string, creatorId: string): Promise<GameState> => {
        const response = await axios.post(`${API_URL}/${gameId}/restart`, { creatorId });
        return response.data;
    },

    playCard: async (gameId: string, playerId: string, cardIds: string[]): Promise<GameState> => {
        console.log(`Attempting to play cards: gameId=${gameId}, playerId=${playerId}, cardIds=${cardIds}`);
        const response = await axios.post(`${API_URL}/${gameId}/play-card`, { playerId, cardIds });
        return response.data;
    },

    selectWinner: async (gameId: string, winningPlayerId: string): Promise<GameState> => {
        const response = await axios.post(`${API_URL}/${gameId}/select-winner`, { winningPlayerId });
        return response.data;
    },

    revealCard: async (gameId: string, playerId: string): Promise<{ game: GameState }> => {
        const response = await axios.post(`${API_URL}/${gameId}/reveal-card`, { playerId });
        return response.data;
    },

    getCurrentGames: async (): Promise<GameInfo[]> => {
        const response = await axiosInstance.get(`${API_URL}/current-players`);
        console.log("response", response.data);
        return response.data.games;
    },

    getAvailablePacks: async () => {
        const response = await axios.get(`${API_URL}/available-packs`);
        return response.data.availablePacks;
    },

    updateOnlineStatus: async (gameId: string, playerId: string, isOnline: boolean): Promise<void> => {
        await axios.post(`${API_URL}/${gameId}/online-status`, { playerId, isOnline });
    },

    getOnlineUsers: async (gameId: string): Promise<{ id: string, name: string }[]> => {
        const response = await axios.get(`${API_URL}/${gameId}/online-users`);
        return response.data.onlineUsers;
    },

    sendChatMessage: async (gameId: string, message: ChatMessage): Promise<void> => {
        await axios.post(`${API_URL}/${gameId}/chat`, message);
    },

    getOlderMessages: async (gameId: string, page: number): Promise<{ messages: ChatMessage[], hasMore: boolean }> => {
        const response = await axios.get(`${API_URL}/${gameId}/older-messages`, { params: { page } });
        return response.data;
    },

    // Card pack management endpoints
    createPack: async (name: string, isPublic: boolean, imageUrl?: string): Promise<CardPack> => {
        try {
            const response = await axiosInstance.post(`${API_URL}/packs`, {
                name,
                isPublic,
                imageUrl
            });
            console.log('CreatePack API - Response:', response.data);
            return response.data;
        } catch (error) {
            console.error('CreatePack API - Error:', error);
            throw error;
        }
    },

    deletePack: async (packId: string): Promise<void> => {
        await axios.delete(`${API_URL}/packs/${packId}`);
    },

    createCard: async (packId: string, text: string, type: 'black' | 'white', blanks?: number): Promise<void> => {
        try {
            await axiosInstance.post(`${API_URL}/cards`, {
                pack: packId,
                text,
                type,
                blanks
            });
        } catch (error) {
            console.error('Error creating card:', error);
            throw error;
        }
    },

    editCard: async (cardId: string, text: string, type: 'black' | 'white', blanks?: number): Promise<void> => {
        const payload = { text, type };
        if (type === 'black' && blanks !== undefined) {
            Object.assign(payload, { blanks });
        }
        await axios.put(`${API_URL}/cards/${cardId}`, payload);
    },

    deleteCard: async (cardId: string): Promise<void> => {
        if (!cardId) {
            throw new Error('Card ID is undefined');
        }
        await axios.delete(`${API_URL}/game/cards/${cardId}`);
    },

    getPackCards: async (packId: string): Promise<Card[]> => {
        const response = await axios.get(`${API_URL}/packs/${packId}/cards`);
        return response.data;
    },

    // Authentication endpoints
    signup: async (username: string, email: string, password: string): Promise<{ token: string }> => {
        const response = await axios.post(`${AUTH_URL}/signup`, { username, email, password });
        return response.data;
    },

    login: async (username: string, password: string): Promise<{ token: string }> => {
        const response = await axios.post(`${AUTH_URL}/login`, { username, password });
        return response.data;
    },

    joinWithoutSignup: async (username: string): Promise<{ token: string }> => {
        const response = await axios.post(`${AUTH_URL}/join-without-signup`, { username });
        return response.data;
    },

    uploadDeckImage: async (formData: FormData) => {
        console.log('Starting image upload...');
        console.log('API URL:', `${IMAGE_URL}/upload`);
        console.log('FormData contents:', Array.from(formData.entries()));

        try {
            const response = await axios.post(`${IMAGE_URL}/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log('Upload successful:', response.data);
            return response.data;
        } catch (error: any) {
            console.error('Upload failed:', error);
            console.error('Error response:', error.response?.data);
            console.error('Error status:', error.response?.status);
            throw error;
        }
    },

    // Add these new admin endpoints
    admin: {
        getCollections: async (): Promise<string[]> => {
            const response = await axiosInstance.get(`${ADMIN_URL}/collections`);
            return response.data;
        },

        getDocuments: async (collection: string, page: number = 1): Promise<{
            docs: any[];
            total: number;
            pages: number;
        }> => {
            const response = await axiosInstance.get(
                `${ADMIN_URL}/documents/${collection}`,
                { params: { page } }
            );
            return response.data;
        },

        updateDocument: async (collection: string, id: string, data: any): Promise<void> => {
            try {
                const response = await axiosInstance.put(
                    `${ADMIN_URL}/documents/${collection}/${id}`,
                    data
                );
                return response.data;
            } catch (error: any) {
                console.error('API Error:', error.response?.data || error);
                throw error;
            }
        },

        deleteDocument: async (collection: string, id: string): Promise<void> => {
            try {
                const response = await axiosInstance.delete(
                    `${ADMIN_URL}/documents/${collection}/${id}`
                );
                return response.data;
            } catch (error: any) {
                if (error.response?.status === 404) {
                    throw new Error('Document not found');
                }
                console.error('API Error:', error.response?.data || error);
                throw error;
            }
        },

        createDocument: async (collection: string, data: any): Promise<void> => {
            await axiosInstance.post(
                `${ADMIN_URL}/documents/${collection}`,
                data
            );
        },
    },
};
